<template>
  <div class="cash">
    <div class="categories">
      <h3>Категории</h3>
      <div class="category-list">
        <div
            v-for="(category, index) in categories"
            :key="index"
            @click="getProductsByCategory(category.id)"
            :class="['category-item', { active: category_id === category.id }]"
        >
          <p>{{ category.name }}</p>
        </div>
      </div>
    </div>

    <div class="products">
      <p class="good__name">Товары</p>
      <input
          v-model="search"
          class="good__search"
          type="text"
          placeholder="Поиск..."
      />

      <div class="product-list">
        <div
            v-for="(product, product_index) in products"
            :key="product_index"
            class="product-item"
        >
          <div class="product-actions">
            <i @click="openHistory(product.count_type, product.product_logs)" class="mdi mdi-history"></i>
            <i @click="openProductCount(product.id)" class="mdi mdi-plus-box"></i>
          </div>

          <h3>{{ product.name }}</h3>
          <p><b>Себестоимость:</b> {{ product.self_price }} тнг</p>
          <p><b>Розничная цена:</b> {{ product.price }} тнг</p>
          <p>
            <b>Остаток:</b> {{ product.quantity }}
            <span v-if="product.count_type === 'count'"> шт</span>
            <span v-if="product.count_type === 'kg'"> кг</span>
            <span v-if="product.count_type === 'litr'"> литр</span>
          </p>
        </div>
      </div>

      <v-pagination v-if="pageCount" v-model="page" :length="pageCount" :total-visible="3"></v-pagination>

      <!-- History Dialog -->
      <v-dialog width="50%" v-model="show_history">
        <v-card>
          <v-card-title>История</v-card-title>
          <v-card-text>
            <div v-for="(quantites, date) in history_logs" :key="date">
              <p>{{ date }}:</p>
              <p><b>Продано:</b> {{ sumOfQuantites(quantites) }} шт</p>
              <p><b>Прибыль:</b> {{ sumOfPrice(quantites) - sumOfSelfPrice(quantites) }} тнг</p>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Product Count Dialog -->
      <v-dialog width="50%" v-model="product_count_open">
        <v-card>
          <v-card-title>Изменить количество</v-card-title>
          <v-card-text>
            <v-text-field v-model="count" label="Количество" required outlined type="tel"></v-text-field>
            <v-text-field v-model="self_price" label="Себестоимость" required outlined type="tel"></v-text-field>
            <v-text-field v-model="price" label="Розничная цена" required outlined type="text"></v-text-field>
            <v-btn @click="saveProductCount" color="primary">Сохранить</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { debounce } from 'lodash';

export default {
  name: "StockPage",
  data() {
    return {
      search: '',
      page: 1,
      pageCount: '',
      history_logs: [],
      show_history: false,
      categories: [],
      products: [],
      category_id: '',
      product_count_open: false,
      count: 0,
      product_id: '',
      self_price: 0,
      price: 0
    };
  },
  mounted() {
    this.getPayments();
    this.getCategories();
  },
  computed: {
    groupedLogs() {
      return this.groupLogsByDate(this.products);
    }
  },
  methods: {
    saveProductCount() {
      if (!this.validateProductCount()) return;

      const data = {
        count: this.count,
        product_id: this.product_id,
        price: this.price,
        self_price: this.self_price
      };

      this.$axios.post(`${this.$API_URL}product/count`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      })
          .then(() => {
            this.showToast('Успешно сохранено');
            this.product_count_open = false;
            this.getProductsByCategory(this.category_id);
          })
          .catch(this.handleError);
    },

    validateProductCount() {
      if (!this.price) {
        this.showToast('Укажите розничную цену');
        return false;
      }
      if (!this.self_price) {
        this.showToast('Укажите себестоимость');
        return false;
      }
      return true;
    },

    showToast(message) {
      this.$toast.open({
        message,
        type: "warning",
        position: "bottom",
        duration: 5000,
        queue: true
      });
    },

    openProductCount(product_id) {
      this.product_id = product_id;
      this.product_count_open = true;
    },

    searchProduct() {
      this.debounceSearchProduct();
    },

    debounceSearchProduct: debounce(function () {
      this.$axios.get(`${this.$API_URL}product?search=${this.search}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      })
          .then(res => {
            this.products = res.data.data;
            this.pageCount = res.data.last_page;
            localStorage.setItem('products', JSON.stringify(this.products));
          })
          .catch(this.handleError);
    }, 1000),

    sumOfQuantites(quantities) {
      return (-1) * quantities.reduce((sum, item) => sum + item.quantity, 0);
    },

    sumOfPrice(quantities) {
      return quantities.reduce((sum, item) => sum + item.price, 0);
    },

    sumOfSelfPrice(quantities) {
      return quantities.reduce((sum, item) => sum + item.self_price, 0);
    },

    openHistory(count_type, logs) {
      this.show_history = true;
      this.history_logs = this.groupLogsByDate(logs);
    },

    groupLogsByDate(logs) {
      return logs.reduce((acc, log) => {
        if (log && log.quantity !== undefined && log.created_at) {
          if (log.quantity < 0) {
            const date = moment(log.created_at).format('YYYY-MM-DD');
            if (!acc[date]) acc[date] = [];
            acc[date].push(log);
          }
        } else {
          console.error("Invalid log entry:", log);
        }
        return acc;
      }, {});
    },

    getProductsByCategory(id, page = 1) {
      this.category_id = id;
      this.$axios.get(`${this.$API_URL}product?category_id=${this.category_id}&page=${page}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      })
          .then(res => {
            this.products = res.data.data;
            this.pageCount = res.data.last_page;
            localStorage.setItem('products', JSON.stringify(this.products));
          })
          .catch(this.handleError);
    },

    getCategories() {
      this.$axios.get(`${this.$API_URL}category`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      })
          .then(res => this.categories = res.data)
          .catch(this.handleError);
    },

    getPayments() {
      this.$axios.get(`${this.$API_URL}payments`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      })
          .then(res => this.payment_types = res.data)
          .catch(this.handleError);
    },

    handleError(error) {
      console.error(error);
      this.$toast.open({
        message: 'Произошла ошибка. Попробуйте позже.',
        type: 'error',
        position: 'bottom',
        duration: 5000,
        queue: true
      });
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.searchProduct();
      } else {
        this.getProductsByCategory(this.category_id, 1);
      }
    },
    page(newPage) {
      this.getProductsByCategory(this.category_id, newPage);
    }
  }
};
</script>

<style scoped>
.cash {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100vh;
  padding: 20px;
}

.categories {
  flex: 1;
  padding-right: 20px;
}

.category-list {
  display: flex;
  flex-direction: column;
}

.category-item {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 5px;
  transition: background 0.3s;
}

.category-item.active,
.category-item:hover {
  background: #0069ff;
  color: white;
}

.products {
  flex: 3;
  padding-left: 20px;
  overflow-y: auto;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.product-item {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.product-item:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.product-actions i {
  cursor: pointer;
  margin-right: 10px;
  font-size: 20px;
}

.product-actions i:hover {
  color: #0069ff;
}

.good__search {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.v-dialog .v-card-title {
  font-weight: bold;
}

.v-text-field {
  margin-bottom: 15px;
}

.v-btn {
  width: 100%;
}

</style>
